import Swiper from 'swiper';
import { Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css';

export default class TestimonialsSwiper {
    constructor() {
        const wrapper = document.querySelectorAll('.testimonials-swiper-wrapper');

        if(!wrapper.length) {
            return;
        }

        wrapper.forEach((wrap) => {
            const TestimonialsSwiper = new Swiper('.testimonials-swiper', {

                pagination: {
                    el: '.swiper-pagination',
                  },

                  autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                  },

                slidesPerView: 1,
                spaceBetween: 20,
                loop: true,

                modules: [ Pagination, Autoplay ],

            })
        })
    }
}