import Swiper from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';

import 'swiper/css';

export default class LogossSwiper {
    constructor() {
        const wrapper = document.querySelectorAll('.logos-swiper-wrapper');

        if(!wrapper.length) {
            return;
        }

        wrapper.forEach((wrap) => {
            const LogosSwiper = new Swiper('.logos-swiper', {

                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                },

                  autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                  },

                slidesPerView: 4,
                spaceBetween: 50,
                loop: true,

                breakpoints: {
                    1025: {
                        slidesPerView: 8, 
                        autoplay: {
                            delay: 2500,
                        }
                        
                    }
                },

                modules: [ Navigation, Autoplay ],

            })
        })
    }
}